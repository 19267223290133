<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="partner.trustee_sole_primary"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/trustees/executors_as_trustees'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'TrusteesPartner',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    isValidated() {
      return true
    },
    number() {
      return '2'
    },
    heading() {
      return (
        'Would you like to name ' +
        this.partner.full_name +
        ' as your main trustee?'
      )
    },
    subHeading() {
      return (
        "It is common to have your partner or spouse named as the main trustee.  You can then name 'secondary trustees' who will step in " +
        'should ' +
        this.partner.full_name +
        ' pass away before you.'
      )
    },
    forwardTo() {
      if (this.partner.trustee_sole_primary) return '/trustees/secondary'
      return '/trustees/primary'
    }
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        this.person = value
      }
    }
  },
  data() {
    return {
      loading: false,
      person: null
    }
  },
  methods: {
    save() {
      if (this.person) {
        let personCopy = Object.assign({}, this.person)
        personCopy.trustee_sole_primary = !personCopy.trustee_sole_primary
        this.$store.commit('personAdd', personCopy)
      }
      this.resetTrustees()
    },
    resetTrustees() {
      this.people.forEach((person) => {
        let personCopy = Object.assign({}, person)
        personCopy.trustee_primary = false
        personCopy.trustee_secondary = false
        personCopy.trustee_backup = false
        this.$store.commit('personAdd', personCopy)
      })
      this.$store.commit('details', {
        trustees_backup_required: false
      })
    }
  }
}
</script>

<style></style>
